import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import TimeLine from 'components/Products/CommentList/MqttIFTTTList';
export const _frontmatter = {
  "title": "INSTAR MQTT Topics and Smarthome User Interfaces",
  "path": "/Frequently_Asked_Question/INSTAR_MQTT_User_Interface/",
  "dateChanged": "2020-03-23",
  "author": "Mike Polinowski",
  "excerpt": "The are so many different MQTT Topics for my camera. Which one of them do I need for my smarthome user interface - the Status, Command or RAW Topic.",
  "image": "./FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "Full HD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='FAQs - INSTAR MQTT Topics and Smarthome User Interfaces' dateChanged='2020-03-23' author='Mike Polinowski' tag='INSTAR IP Camera' description='The are so many different MQTT Topics for my camera. Which one of them do I need for my smarthome user interface - the Status, Command or RAW Topic.' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/INSTAR_MQTT_User_Interface/' locationFR='/fr/Frequently_Asked_Question/INSTAR_MQTT_User_Interface/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "instar-mqtt-topics-and-smarthome-user-interfaces",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#instar-mqtt-topics-and-smarthome-user-interfaces",
        "aria-label": "instar mqtt topics and smarthome user interfaces permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR MQTT Topics and Smarthome User Interfaces`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: The are so many different MQTT Topics for my camera. Which one of them do I need for my smarthome user interface - the `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/INSTAR_Command_Status_and_RAW_Topics/"
      }}>{`Status, Command or RAW Topic`}</a>{`?`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <TimeLine mdxType="TimeLine" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><strong parentName="p">{`A`}</strong>{`: There are two types of smarthome UIs, as shown in the graphic below. On the `}<strong parentName="p">{`left`}</strong>{` is a user interface like the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/ioBroker/"
      }}>{`ioBroker Vis`}</a>{`. All you need to do is adding a button and assigning it the `}<strong parentName="p">{`RAW Command Topic`}</strong>{` that you want to update. Clicking the button will send the command and your camera will update it's configuration - e.g. switch on the motion detection area.`}</p>
    <p>{`On the right is an UI like the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/OpenHAB2/"
      }}>{`OpenHAB 2 PaperUI`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Home_Assistant/"
      }}>{`Home Assistant Lovelace UI`}</a>{` or the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Node-RED/"
      }}>{`Node-RED Dashboard`}</a>{`. Clicking the switch will send the command topic, but the switch will only switch to the new state - `}<strong parentName="p">{`ON`}</strong>{` - after your camera confirms the command with a `}<strong parentName="p">{`Status Topic`}</strong>{` update:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1036ef8401e75153ec3025e5c08b53c1/5a190/MQTT_State_and_Command_Topics.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.43478260869564%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB9klEQVQ4y42USW/bMBCF9a9zzi3X/I8AueeSY2AYaBYUWdwYzlJvcuPIokWRw+UrSFuN0wZpBngQMXocDt88qQAI3vNUltzOZoi1KUWMMSO/DwErQvSe4XTK/WKBE/nDY4dbsF0sTk8ZHxzg4cOCYkxez46PmR4eEnd5vEXhrSX1FE5OiHt7iAiidSbtFtRKkXoKR0fE/f28x+3wOhS2aZBeD393hxuP8RcXmPH43akp1sslrtfDXV/jJhP81RVmMvmHV7Rty/r1dYPlknVd0zQNNnW+hTGG1WrFuqo2nMRNPK3f8RKKdJ2QrhLjG0LAe5+RBuacy0XjJ7zMDYGCL0TaLNup/i+KdLqyCiU1jTTUskLbhkYpfpUl8+k0W0QnresaScNZr5Gqyk9V1yzKknI2IzhH0dqW7+0556bH0NxxafrM25/MH54Z3t5mzaK1qJcX2rMzTBrazQ2m38c8PfE4GvF8f09TVURjKDrRO1gjiBV022bRfQg5r5TCWEsaYuaKYJzQ6CZzffDZ/JuhfIAYNr5K6yRLuzV2p2n2aOjcDTFs/Pi1oaQvxdpslWq5TAmCE7Rdo0XRiqaRmtZqil2Xf4SuoDOGh8GA0WAAzmPUigd7ydD3mbofjPw35vrx8w7//jm45Envu1tiJZnZZEMn3ZM0vwGmZT3/Cb3RdgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1036ef8401e75153ec3025e5c08b53c1/e4706/MQTT_State_and_Command_Topics.avif 230w", "/en/static/1036ef8401e75153ec3025e5c08b53c1/d1af7/MQTT_State_and_Command_Topics.avif 460w", "/en/static/1036ef8401e75153ec3025e5c08b53c1/c9875/MQTT_State_and_Command_Topics.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1036ef8401e75153ec3025e5c08b53c1/a0b58/MQTT_State_and_Command_Topics.webp 230w", "/en/static/1036ef8401e75153ec3025e5c08b53c1/bc10c/MQTT_State_and_Command_Topics.webp 460w", "/en/static/1036ef8401e75153ec3025e5c08b53c1/d00b9/MQTT_State_and_Command_Topics.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1036ef8401e75153ec3025e5c08b53c1/81c8e/MQTT_State_and_Command_Topics.png 230w", "/en/static/1036ef8401e75153ec3025e5c08b53c1/08a84/MQTT_State_and_Command_Topics.png 460w", "/en/static/1036ef8401e75153ec3025e5c08b53c1/5a190/MQTT_State_and_Command_Topics.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1036ef8401e75153ec3025e5c08b53c1/5a190/MQTT_State_and_Command_Topics.png",
              "alt": "INSTAR MQTT and Smarthome User Interfaces",
              "title": "INSTAR MQTT and Smarthome User Interfaces",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can test each of those steps with programs like `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Windows_macOS_and_LINUX_Apps/"
      }}>{`MQTT.fx or the MQTT Explorer`}</a>{` and verify that everything is working as expected - before attempting to use the MQTT Topics inside your smarthome UI:`}</p>
    <p><img parentName="p" {...{
        "src": "/en/85f86b56835aa86bc445c1208cd6dd64/MQTT_State_and_Command_Topics.gif",
        "alt": "INSTAR MQTT and Smarthome User Interfaces"
      }}></img></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      